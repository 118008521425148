// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-muut-js": () => import("./../../../src/pages/muut.js" /* webpackChunkName: "component---src-pages-muut-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/blogTemplate.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-templates-blogs-template-js": () => import("./../../../src/templates/blogsTemplate.js" /* webpackChunkName: "component---src-templates-blogs-template-js" */),
  "component---src-templates-inner-page-template-js": () => import("./../../../src/templates/innerPageTemplate.js" /* webpackChunkName: "component---src-templates-inner-page-template-js" */),
  "component---src-templates-main-page-template-js": () => import("./../../../src/templates/mainPageTemplate.js" /* webpackChunkName: "component---src-templates-main-page-template-js" */)
}

